import { gql } from "@apollo/client";

const REMOVE = gql`
  mutation RemoveItemFromCart($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            quantity
            key
            product {
              node {
                id
                name
                slug
                databaseId
                image {
                  sourceUrl
                  altText
                }
                crossSellProducts {
                  bundles {
                    bundleName
                    bundleProducts {
                      productSku
                    }
                  }
                }
                productTags {
                  nodes {
                    name
                  }
                }
                productCategories {
                  nodes {
                    name
                  }
                }
                ... on SimpleProduct {
                  crossSellProducts {
                    bundles {
                      bundleName
                      bundleProducts {
                        productSku
                      }
                    }
                  }

                  related {
                    relatedProducts: nodes {
                      name
                      slug
                      databaseId
                      description
                      image {
                        sourceUrl
                        altText
                      }
                      productTags {
                        nodes {
                          name
                        }
                      }
                      ... on SimpleProduct {
                        id
                        name
                        price
                        regularPrice
                        onSale
                        stockStatus
                        tierPrice {
                          tierPrice {
                            price
                            price2
                            price3
                            price4
                          }
                        }
                      }
                    }
                  }

                  upsell {
                    upsellProducts: nodes {
                      name
                      slug
                      databaseId
                      description
                      image {
                        sourceUrl
                        altText
                      }
                      productTags {
                        nodes {
                          name
                        }
                      }
                      ... on SimpleProduct {
                        id
                        name
                        price
                        regularPrice
                        onSale
                        stockStatus
                        tierPrice {
                          tierPrice {
                            price
                            price2
                            price3
                            price4
                          }
                        }
                      }
                    }
                  }
                  regularPrice
                  price
                  salePrice
                  tierPrice {
                    tierPrice {
                      price
                      price2
                      price3
                      price4
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default REMOVE;
