import {
	Button,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

const APPLY_COUPON_MUTATION = gql`
	mutation ApplyCoupon($clientMutationId: String, $code: String!) {
		applyCoupon(input: { clientMutationId: $clientMutationId, code: $code }) {
			cart {
				appliedCoupons {
					code
				}
				total
			}
		}
	}
`;

const CouponButton = () => {
	const [code, setCouponCode] = useState('');
	const [applyCoupon, { error, loading }] = useMutation(APPLY_COUPON_MUTATION);
	const toast = useToast();

	const handleApplyCoupon = async () => {
		try {
			const { data } = await applyCoupon({
				variables: { code },
			});
			const appliedCoupon = data.applyCoupon.cart.appliedCoupons[0];
			if (appliedCoupon) {
				toast({
					title: 'Coupon Applied',
					description: `The coupon code "${appliedCoupon.code}" has been successfully applied.`,
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setCouponCode('');
			} else {
				toast({
					title: 'Coupon Application Error',
					description: 'The coupon code could not be applied.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: 'Coupon Application Error',
				description: 'An error occurred while applying the coupon.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	function InitialFocus() {
		const { isOpen, onOpen, onClose } = useDisclosure();

		const initialRef = React.useRef(null);
		const finalRef = React.useRef(null);

		return (
			<>
				<Button
					onClick={onOpen}
					bg='transparent'
					size='sm'
					fontWeight='normal'
					pr='0'
					_hover={{ bg: 'transparent', color: '#D28C49' }}
					_active={{ bg: 'transparent' }}>
					Add coupon code
				</Button>
				<Modal
					initialFocusRef={initialRef}
					finalFocusRef={finalRef}
					isOpen={isOpen}
					onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Add your Coupon</ModalHeader>
						<Text
							ml={'1.5rem'}
							textAlign={'left'}
							color={'#D28C49'}>
							Please type coupon code in small caps
						</Text>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
								<Input
									ref={initialRef}
									type='text'
									border='1px solid #D28C49'
									_focusVisible={{ border: '1px solid #D28C49' }}
									_active={{ border: '1px solid #D28C49' }}
									value={code}
									onChange={(e) => setCouponCode(e.target.value)}
									placeholder='Enter coupon code'
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<Button
								bg='#D28C49'
								mr={3}
								color='white'
								_hover={{ bg: '#D28C49', color: 'white' }}
								_active={{ bg: '#D28C49', color: 'white' }}
								onClick={handleApplyCoupon}
								disabled={loading}>
								{loading ? 'Applying coupon...' : 'Apply Coupon'}
							</Button>
							<Button
								onClick={onClose}
								bg='transparent'
								_hover={{ bg: 'transparent', color: 'black' }}
								border='1px solid #000'>
								Close
							</Button>
						</ModalFooter>
						<Text
							py={'16px'}
							px={'8px'}
							textAlign={'center'}>
							{error && <p>Error applying coupon: {error.message}</p>}
						</Text>
					</ModalContent>
				</Modal>
			</>
		);
	}

	return InitialFocus();
};

export default CouponButton;
