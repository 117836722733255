import * as styles from "../product-single-page.module.css";

import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { CartItem } from "./CartItem";
import { CartOrderSummary } from "./CartOrderSummary";
import CART, { Cart as CartType } from "../../../types/Cart";
import UPDATE from "../../../types/Update";
import REMOVE from "../../../types/Remove";
import EmptyCart from "./EmptyCart";
import Loading from "../Helpers/Loading";
import { fetchAllProducts } from "../../queries/fetchAllProducts";
import { getProductPrice } from "../../utils/shopUtils";
import { useAppState } from "../context";
import useAuth from "../useAuth";
import debounce from "lodash.debounce";

type ShoppingCartPageType = {};

type CartQuery = {
  cart?: CartType;
};

const Cart: React.FC<ShoppingCartPageType> = () => {
  // CART
  const { cart, setCart } = useAppState();
  const [removedItems, setRemovedItems] = useState([]);
  const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();

  const [maybeGetCart, { loading }] = useLazyQuery<CartQuery>(CART, {
    onCompleted: ({ cart }) => {
      setCart(cart);
    },
    fetchPolicy: "network-only",
  });

  const [removeAllItems, { loading: removeItemLoading, data }] = useMutation(
    REMOVE,
    {
      onCompleted({ removeItemsFromCart: { cart } }) {
        setCart(cart);
      },
    }
  );

  const [
    updateQuantities,
    { loading: updateQuantityLoading, data: updateData },
  ] = useMutation(UPDATE, {
    onCompleted({ updateItemQuantities: { cart } }) {
      setCart(cart);
    },
  });

  const debouncedUpdateQuantities = debounce((updatedQuantities: any) => {
    updateQuantities({ variables: updatedQuantities });
  }, 500);

  const handleUpdateQuantities = (updatedQuantities: any) => {
    debouncedUpdateQuantities(updatedQuantities);
  };

  const updateLoading = useMemo(() => {
    return updateQuantityLoading || removeItemLoading;
  }, [updateQuantityLoading, removeItemLoading]);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (cart || !initialLoad) {
      return;
    }

    setInitialLoad(false);
    maybeGetCart();
  }, [cart, maybeGetCart]);

  const cartContentNodes = useMemo(() => {
    const nodes = cart?.contents.nodes || [];

    const databaseIdSet = new Set();
    const uniqueProducts: any[] = [];

    if (nodes && nodes.length > 0) {
      nodes.forEach((item: any) => {
        const databaseId = item.product.node.databaseId;

        if (databaseIdSet.has(databaseId)) {
          const duplicateIndex = uniqueProducts.findIndex(
            (product) => product.product.node.databaseId === databaseId
          );
          const duplicateItem = uniqueProducts.find(
            (product) => product.product.node.databaseId === databaseId
          );

          let newItem;

          // we display the item with higher quantity
          if (duplicateItem.quantity > item.quantity) {
            newItem = { ...duplicateItem, gratis: item.quantity };
          } else if (duplicateItem.quantity < item.quantity) {
            newItem = { ...item, gratis: duplicateItem.quantity };
          } else {
            // if quantities are equal, show both
            uniqueProducts.push(item);
          }

          uniqueProducts[duplicateIndex] = newItem;
        } else {
          uniqueProducts.push(item);
          databaseIdSet.add(databaseId);
        }
      });
    }

    return uniqueProducts;
  }, [cart]);

  const productIdToShowBundle = useMemo(() => {
    const productsWithBundles = cartContentNodes.filter((item) =>
      item.product.node.crossSellProducts?.bundles?.map(
        (item: any) => item.bundleName
      )
    );

    if (!productsWithBundles || productsWithBundles.length === 0) {
      return undefined;
    }

    const highestPricedProduct = productsWithBundles?.reduce((max, current) => {
      const currentPrice = parseFloat(
        current.product.node.regularPrice?.replace("$", "")
      );
      const maxPrice = parseFloat(
        max.product.node.regularPrice?.replace("$", "")
      );
      return currentPrice > maxPrice ? current : max;
    });

    return highestPricedProduct?.product?.node?.databaseId;
  }, [cartContentNodes]);

  const isDysportWithQuantity5InCart = useMemo(() => {
    return (
      cart?.contents.nodes?.some((item) => {
        return (
          item.product?.node.name === "DYSPORT® 500u (Czech)" ||
          item.product?.node.name === "DYSPORT® 300u" ||
          item.product?.node.name === "DYSPORT® 500u (English/Korean)" ||
          ((item.product?.node.name === "DYSPORT® 500u (Czech)",
          item.product?.node.name === "DYSPORT® 300u",
          item.product?.node.name === "DYSPORT® 500u (English/Korean)") &&
            item.quantity === 5)
        );
      }) || false
    );
  }, [cart]);

  //SUGGESTED FOR YOU LOGIC
  const allProducts = fetchAllProducts();

  const botulinumItems = cartContentNodes.some((item: any) => {
    const categories = item.product.node?.productCategories?.nodes.map(
      (node: any) => node.name
    );
    return categories?.includes("Botulinum Toxins");
  });

  let productIds: number[] = [];
  if (botulinumItems) {
    productIds = [50159, 50170]; // Botulinum products for eligible users
  } else if (loggedIn || isUnlicensedUser || isCustomerDefault) {
    productIds = [5649, 5493, 5491, 5567]; // All products for logged-in users
  } else {
    productIds = [5567]; // Default products for other users
  }

  const randomProductId = useMemo(() => {
    const randomProductId =
      productIds[Math.floor(Math.random() * productIds.length)];

    return randomProductId;
  }, [botulinumItems, loggedIn, isUnlicensedUser, isCustomerDefault]);

  const product = allProducts.find(
    (product: any) => product.databaseId === randomProductId
  );

  const [productQuantity, setProductQuantity] = useState(1);

  const suggestedPriceToShow = getProductPrice(
    productQuantity,
    product?.tierPrice,
    product?.price
  );

  // GOOGLE ANALYTICS - VIEW CART
  const cartItems = cart?.contents.nodes;

  useEffect(() => {
    // Google Analytics event for viewing the cart
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        items: cartItems?.map((item: any) => ({
          item_name: item.product.node.name,
          item_id: item.product.node.databaseId,
          price: parseFloat(item.product.node.price?.replace("$", "")),
          item_brand: item.brand,
          item_category: item.product.node.productCategories,
          quantity: item.quantity,
        })),
      },
    });
  }, []);

  const handleRemoveAllItems = (item: any) => {
    // Google Analytics event for removing items from the cart
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_name: item.product.node.name,
            item_id: item.product.node.databaseId,
            price: item.product.node.price
              ? parseFloat(item.product.node.price?.replace("$", ""))
              : undefined,
            item_brand: item.brand,
            item_category: item.product.node.productCategories,
            quantity: item.quantity,
          },
        ],
      },
    });

    removeAllItems({
      variables: {
        input: {
          clientMutationId: `1234`,
          all: false,
          keys: item.key,
        },
      },
    });
  };

  // 	// Google Analytics event for removing items from the cart
  // 	// @ts-ignore
  // 	window.dataLayer = window.dataLayer || [];
  // 	// @ts-ignore
  // 	window.dataLayer.push({
  // 		event: 'remove_from_cart',
  // 		ecommerce: {
  // 			items: removedItems?.map((item: any) => ({
  // 				item_name: item.product.node.name,
  // 				item_id: item.product.node.databaseId,
  // 				price: item.product.node.price
  // 					? parseFloat(item.product.node.price.replace('$', ''))
  // 					: undefined,
  // 				item_brand: item.brand,
  // 				item_category: item.product.node.productCategories,
  // 				quantity: item.quantity,
  // 			})),
  // 		},
  // 	});
  // }, [removedItems]);

  useEffect(() => {
    if (removedItems.length > 0) {
      // Google Analytics event for removing items from the cart
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          items: removedItems.map((item: any) => ({
            item_name: item.product.node.name,
            item_id: item.product.node.databaseId,
            price: item.product.node.price
              ? parseFloat(item.product.node.price.replace("$", ""))
              : undefined,
            item_brand: item.brand,
            item_category: item.product.node.productCategories,
            quantity: item.quantity,
          })),
        },
      });
    }
  }, [removedItems]);

  if (loading || !cart) {
    return <Loading />;
  }

  if (cart?.contents.itemCount === 0) {
    return (
      <>
        <EmptyCart />
      </>
    );
  }

  return (
    <>
      <Box
        bg="white"
        width="100%"
        minH="100vh"
        mx="auto"
        px={{ base: "4", md: "8", lg: "8em" }}
        py={{ base: "32", md: "32", lg: "32" }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          align={{ lg: "flex-start" }}
          spacing={{ base: "8", md: "16" }}
        >
          <Stack spacing={{ base: "8", md: "6" }} flex="1">
            {cart?.contents.itemCount > 1 ? (
              <Heading as="h1" fontSize="2xl" fontWeight="extrabold">
                Shopping Cart ({cart?.contents.itemCount} items)
              </Heading>
            ) : (
              <Heading as="h1" fontSize="2xl" fontWeight="extrabold">
                Shopping Cart ({cart?.contents.itemCount} item)
              </Heading>
            )}
            {cartContentNodes.map((item) => {
              const priceToShow = getProductPrice(
                item?.quantity,
                item.product?.node?.tierPrice,
                item.product?.node?.price
              );

              const productName =
                item.variation?.node.name || item.product?.node.name;

              // If it's Dysport with quantity 5, set the price to 'FREE' for Restylan
              // const adjustedPrice =
              // 	isDysportWithQuantity5InCart &&
              // 	item.product?.node.name ===
              // 		'RESTYLANE® SKINBOOSTERS™ VITAL w/ Lidocaine 20mg/ml, 3mg/ml 1-1ml prefilled syringe'
              // 		? 'FREE'
              // 		: item.variation?.node.price || priceToShow;

              const adjustedPrice =
                item.product?.node?.promotions?.onPromotion == true
                  ? item.product.node.price
                  : Number(priceToShow);

              const shouldShowBundle =
                item.product.node.databaseId === productIdToShowBundle;

              return (
                <CartItem
                  onClickDelete={() => handleRemoveAllItems(item)}
                  onChangeQuantity={(qty: any) =>
                    handleUpdateQuantities({
                      key: item.key,
                      quantity: qty,
                    })
                  }
                  databaseId={item.product.node.databaseId}
                  key={item.key}
                  name={productName.slice(0, 16) + "..."} // sliced name
                  price={adjustedPrice}
                  quantity={item.quantity}
                  imageUrl={item.product.node.image?.sourceUrl}
                  altText={item.product.node.image?.altText}
                  productTags={item.product.node.productTags}
                  slug={`/${item.product.node.slug}`}
                  categories={item.product.node.productCategories}
                  gratis={item.gratis}
                  updateLoading={updateLoading}
                  bundles={
                    shouldShowBundle
                      ? item.product.node.crossSellProducts.bundles
                      : null
                  }
                  productName={productName}
                  products={allProducts}
                />
              );
            })}
          </Stack>

          {/* CART ORDER SUMMARY */}

          <Flex direction="column" align="center" flex="1">
            <CartOrderSummary
              subtotal={cart.subtotal}
              total={cart.total}
              shippingTotal={cart.shippingTotal}
              discountTotal={cart.discountTotal}
              appliedCoupons={cart.appliedCoupons}
              updateLoading={updateLoading}
            />
          </Flex>
        </Stack>
        {/* SUGGESTED */}
        <VStack
          alignItems="flex-start"
          gap="24px"
          w={{ base: "100%", lg: "50%" }}
          py="86px"
        >
          <Text as="h2" className={styles.productsYouMay}>
            Suggested for you:
          </Text>
          <CartItem
            isSuggested
            onChangeQuantity={(qty: any) => setProductQuantity(qty)}
            databaseId={product.databaseId}
            key={product.key}
            name={product.name}
            // Use the adjusted price
            price={suggestedPriceToShow}
            quantity={product.quantity}
            imageUrl={product.image?.sourceUrl}
            productTags={product.productTags}
            slug={`/${product.slug}`}
            categories={product.productCategories}
            gratis={product.gratis}
          />
        </VStack>
      </Box>
    </>
  );
};

export default Cart;
