import * as React from 'react';
import {
  Box,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';

export type CartProductMetaProps = {
  name?: string;
  image?: string;
  quantity: number;
  gratis?: number;
  altText?: string;
  productTags?: {
    nodes?: [
      {
        name: string;
      }
    ];
  };
};

export const CartProductMeta = (props: CartProductMetaProps) => {
  const { image, name, productTags, quantity, gratis, altText } = props;
  return (
    <Stack direction='row' spacing='5' width='full' mb='2'>
      <Image
        rounded='lg'
        width='120px'
        height='120px'
        fit='cover'
        src={image}
        alt={altText}
        draggable='false'
        loading='lazy'
        fallback={<Skeleton />}
      />
      <Box pt='4'>
        <Stack spacing='0.5'>
          <Text fontWeight='bold' textTransform='uppercase'>
            {name}{' '}
          </Text>
          <HStack>
            {gratis && gratis > 0 && (
              <Text color='#D28C49' fontWeight='bold'>
                {`+${gratis} discounted`}
              </Text>
            )}
          </HStack>
          <Text color={mode('gray.600', 'gray.700')} fontSize='sm' display='flex'>
            {productTags?.nodes?.map((item: any, index, array) => {
              const lastElement = array.length - 1;
              if (
                item.name === 'Unlicensed' ||
                item.name === 'Medical Devices' ||
                item.name === 'Pharmaceuticals'
              ) {
                return null;
              }
              if (lastElement == index) {
                return (
                  <Text color='#404040' fontWeight='400'>
                    {item.name}{' '}
                  </Text>
                );
              }
              return (
                <Text color='#404040' fontWeight='400' marginRight='2'>
                  {item.name}
                </Text>
              );
            })}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};
