import * as React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  OrderedList,
  Input,
  Select,
} from "@chakra-ui/react";
import { gql, useQuery } from "@apollo/client";
import useAuth, { User } from "../useAuth";

export const GET_CUSTOMER = gql`
  query GetCustomer($customerId: Int) {
    customer(customerId: $customerId) {
      firstName
      email
      shipping {
        address1
        address2
        city
        company
        postcode
        country
      }
      billing {
        address2
      }
      orders {
        nodes {
          id
          status
          total
          orderKey
          orderNumber
          dateCompleted
          date
          customerNote
          currency
          paymentMethod
          paymentMethodTitle
          modified
          subtotal
          shippingTotal
          shippingTax
          orderVersion
          datePaid
          needsPayment
          needsProcessing
          needsShippingAddress
          trackingOptions {
            trackingNumber
            selectCourier 
          }
        }
      }
    }
  }
`;

const ShoppingHistory = () => {
  const { user } = useAuth();
  const { id, email } = user as User;

  const customer = useQuery(GET_CUSTOMER, {
    variables: { id },
  });

  return (
    <Container bg="gray.50" maxW="100%" p={{ base: "4", md: "16" }}>
      <Box maxW="100%" display="block">
        <Heading as="h1" fontSize="2xl!important" py="24px" textAlign="left">
          {" "}
          Your Shopping History{" "}
        </Heading>
        {customer?.data?.customer.orders.nodes.length == 0 ? (
          <Text fontWeight={700} textAlign="center">You don't have any orders yet.</Text>
        ) : (
          // <><Box fontSize="xl" my="6" display="flex" alignItems="center">
          //     <Text fontWeight={700} id="as-root" mr="4">Track your order:</Text>
          //     <div data-domain="" data-size="medium" className="as-track-button"></div>
          // </Box>
          //     <Text>*Please check your email in order to find tracking number</Text></>
          // <HStack>
          //   <Box
          //     w="100%"
          //     id="tm-my-tp-container"
          //     data-key="1qRX1ZieyN1qlpdpa25nmJpl3aOSy5VXp88="
          //   ></Box>
          // </HStack>
          <></>
        )}

        <SimpleGrid columns={{ lg: 4, md: 3, sm: 2 }} spacing={6}>
          {customer.data?.customer.orders.nodes.length == 0 && <>You don't have any orders yet.</>}
          {customer?.data?.customer.orders.nodes.map((e: any) => (
            <Box
              backgroundColor="white"
              p="8"
              marginInlineStart="0px !important"
              mb="16px"
              w={{ md: "auto", base: "100%" }}
              boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
              borderRadius="8px"
            >
              <Heading as="h3" size="md!important" pb="24px">
                Order Number: {e.orderNumber}
              </Heading>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Status
                  </Text>
                  {e.status === "PROCESSING" && (
                    <>
                      <Text color="orange" pt="2" fontSize="sm">
                        {e.status}
                      </Text>
                    </>
                  )}
                  {e.status === "FAILED" && (
                    <>
                      <Text color="red" pt="2" fontSize="sm">
                        {e.status}
                      </Text>
                    </>
                  )}
                  {e.status === "CANCELLED" && (
                    <>
                      <Text color="red" pt="2" fontSize="sm">
                        {e.status}
                      </Text>
                    </>
                  )}
                  {e.status === "COMPLETED" && (
                    <>
                      <Text color="green" pt="2" fontSize="sm">
                        {e.status}
                      </Text>
                    </>
                  )}
                  {e.status === "PENDING" && (
                    <>
                      <Text fontWeight={700} color="orange" pt="2" fontSize="sm">
                        {e.status} PAYMENT
                      </Text>
                    </>
                  )}
                  {e.status === "ON_HOLD" && (
                    <>
                      <Text color="orange" pt="2" fontSize="sm">
                        {e.status}
                      </Text>
                    </>
                  )}
                  {/* {e.status && <Text fontWeight={700} pt='2' fontSize='sm'> {e.status}</Text>} */}
                </Box>
                <Box p="2">
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Your tracking number
                  </Text>
                  <Text pt="2" fontSize="sm">
                    {e.trackingOptions.trackingNumber}
                  </Text>
                  <Text><Link href={e.trackingOptions.selectCourier}>{e.trackingOptions.selectCourier}</Link></Text>
                 
                  {/* <HStack>
                  <img width="60px" height="60px" src={e.trackingNumber.selectCourier.courierIcon?.sourceUrl} />
                  <Text fontWeight={700} fontWeight="700">{e.trackingNumber.selectCourier.courierName}</Text>
                  </HStack>
                  <Button size="sm" bg="transparent" border="1px solid black" borderRadius="25px" _hover={{bg: "black", color: "white", }} _active={{bg: "transparent"}}><a href={e.trackingNumber.selectCourier.courierLink} target="_blankText">Track Your Order</a></Button> */}
                </Box>
                <Box>
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Needs payment?
                  </Text>
                  <Text pt="2" fontSize="sm">
                    {e.needsPayment ? "Yes" : "No"}
                  </Text>
                </Box>
                {e.needsPayment ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <Box>
                      <Text fontWeight={700} size="xs" textTransform="uppercase">
                        Date paid
                      </Text>
                      <Text pt="2" fontSize="sm">
                        {e.datePaid}
                      </Text>
                    </Box>
                  </>
                )}

                <Box>
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Payment Info
                  </Text>
                  <Text fontWeight={700} pt="2" fontSize="sm">
                    {" "}
                    Total: {e.total}
                  </Text>
                  <Text fontWeight={700} pt="2" fontSize="sm">
                    {" "}
                    Shipping total: {e.shippingTotal}
                  </Text>
                  <Text fontWeight={700} pt="2" fontSize="sm">
                    {" "}
                    Subtotal: {e.subtotal}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Payment Method
                  </Text>
                  <Text pt="2" fontSize="sm">
                    {e.paymentMethodTitle}
                  </Text>
                </Box>
                {e.dateCompleted ? (
                  <>
                    <Box>
                      <Text fontWeight={700} size="xs" textTransform="uppercase">
                        Date Completed
                      </Text>
                      <Text pt="2" fontSize="sm">
                        {e.dateCompleted}
                      </Text>
                    </Box>
                  </>
                ) : (
                  ""
                )}

                <Box>
                  <Text fontWeight={700} size="xs" textTransform="uppercase">
                    Needs processing?
                  </Text>
                  <Text fontWeight={700} pt="2" fontSize="sm!important">
                    {e.needsProcessing ? "yes" : "no"}
                  </Text>
                </Box>
              </Stack>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default ShoppingHistory;
