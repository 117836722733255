import { Button, Link, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { useAppState } from "./context";

const ADD_TO_CART = gql`
  mutation ATC($input: AddToCartInput!) {
    addToCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            quantity
            product {
              node {
                name
                sku
                databaseId
                id
                name
                slug
                databaseId
                image {
                  sourceUrl
                  altText
                }
                crossSellProducts {
                  bundles {
                    bundleName
                    bundleProducts {
                      productSku
                    }
                  }
                }
                productTags {
                  nodes {
                    name
                  }
                }
                productCategories {
                  nodes {
                    name
                  }
                }
                ... on SimpleProduct {
                  price
                  regularPrice
                  salePrice
                  crossSellProducts {
                    bundles {
                      bundleName
                      bundleProducts {
                        productSku
                      }
                    }
                  }
                  upsell {
                    upsellProducts: nodes {
                      name
                      slug
                      databaseId
                      description
                      image {
                        sourceUrl
                      }
                      productTags {
                        nodes {
                          name
                        }
                      }
                      ... on SimpleProduct {
                        id
                        name
                        price
                        regularPrice
                        onSale
                        stockStatus
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AddToCart: React.FC<{
  productId: number;
  name: String;
  price: String;
}> = ({ productId, name, price }) => {
  const toast = useToast();
  const { setCart } = useAppState();
  const [addToCart, { loading, error }] = useMutation(ADD_TO_CART, {
    onCompleted: ({ addToCart }) => {
      toast({
        title: (
          <Text>
            Added to&nbsp;
            <Link href="/cart" fontWeight={700} textDecoration="underline">
              cart
            </Link>
          </Text>
        ),
        status: "success",
      });
      setCart(addToCart.cart);

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_name: name,
              item_id: productId,
              price: price ? parseFloat(price.replace("$", "")) : undefined,
              quantity: "1",
            },
          ],
        },
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "There was an error adding your product",
        status: "error",
      });
    },
  });

  function handleAddToCart() {
    addToCart({
      variables: { input: { productId, quantity: 1 } },
    });
  }

  return (
    <Button
      isLoading={loading}
      onClick={() => handleAddToCart()}
      loadingText="Adding to cart"
      variant="outline"
      w="100%"
      fontSize="12px"
      fontWeight={600}
      border="1px solid #F8F1F0"
      color="#000"
      borderRadius="40px"
    >
      <img alt="" src="/shoppingcartplus.svg" />
      ADD TO CART
    </Button>
  );
};

export default AddToCart;
