import * as styles from "../Cart/shopping-cart.module.css";

import { Box, Button, Link, Stack } from "@chakra-ui/react";

import { IconArrowRight } from "@tabler/icons";
import React from "react";
import jwtDecode from "jwt-decode";
import useAuth from "../useAuth";
import { useState } from "react";

const CheckOutButton = ({ isDisabled }: any) => {
  const { loggedIn, user } = useAuth();
  const [session] = useState(() => {
    try {
      if (typeof window !== "undefined") {
        const jwtSession = window.localStorage.getItem("woo-session");

        const decoded = jwtDecode<{ data: { customer_id: String } }>(
          // @ts-ignore
          jwtSession
        );

        return decoded.data.customer_id;
      }
    } catch (error: any) {
      console.error(error.message);

      return null;
    }
  });
  return (
    <Box w="100%">
      <Link
        href={`https://shop.doctormedica.co/checkout/?session_id=${session}`}
		_hover={{ bg: "transparent", color: "#fff !important" }}
        w="100%"
      >
        <Button
          position="relative"
		  w="100%"
          border="0.4px solid"
          borderColor=""
		  fontWeight={700}
          borderRadius="1000px"
          padding="12px 20px"
          fontSize="14px"
          lineHeight={1}
          h="45px"
          color="#120B0C"
          bg="transparent"
		  textTransform="uppercase"
          _hover={{ bg: "#120B0C", color: "#fff" }}
          isDisabled={isDisabled}
        >
         Checkout
        </Button>
      </Link>
    </Box>
  );
};

export default CheckOutButton;
