import * as React from 'react';

import Cart from '../components/Cart/Cart';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';

const CartPage = () => {
	return (
		<Layout>
			<Cart />
		</Layout>
	);
};

export default CartPage;

export const Head = () => (
	<SEO
		title='Shopping Cart - DoctorMedica.co'
		description='Here is a list of all items in your cart. Check out now or call us with any questions you may have.'
	/>
);
